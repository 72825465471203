<template>
  <div :style="menuBaseStyle" class="">
    <v-row>
      <v-col cols="4" class="px-6 cursor-pointer" @click="navigateToOwn">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Eigen.png" height="40px" alt="eigen">
        </div>
        <div class="text-blue d-flex align-end justify-center">
          Eigen
        </div>
      </v-col>
      <v-col cols="4" class="px-6 cursor-pointer" @click="navigateToWise">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Wijs.png" height="40px" alt="Wijs">
        </div>
        <div class="text-blue d-flex align-end justify-center">
          Wijs
        </div>

      </v-col>
      <v-col cols="4" class="px-6 cursor-pointer" @click="navigateToDevelopment">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Ontwikkelen.png" height="40px" alt="Ontwikkelen">
        </div>
        <div class="text-blue d-flex align-end justify-center">
          Ontwikkelen
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>


export default {
  name: "FooterDashboard",
  data() {
    return {
      menuBaseStyle: {
        "background-color": this.$vuetify.theme.themes.light.white,
        height: "80px",
        "padding-top": "10px",
        position: "fixed",
        bottom: "5px",
        width: "100%",
        "z-index": "1000",
      },
      menuBaseLogoStyle: {
        height: "40px"
      }
    }
  },
  methods: {
    navigateToOwn() {
      this.$router.push({name: "dashboardOwn"})
    },
    navigateToWise() {
      this.$router.push({name: "dashboardWise"})
    },
    navigateToDevelopment() {
      this.$router.push({name: "dashboardDevelopment"})
    }
  }
};
</script>

<style scoped>
.text-blue {
  color: #004572;
}
</style>
