<template>
  <v-app>
    <v-main>
      <menu-dashboard />

      <div style="margin-top: 80px; margin-bottom: 120px">
        <router-view />
      </div>

      <footer-dashboard />
    </v-main>
  </v-app>
</template>

<script>

import MenuDashboard from "@/menu/dashboard";

import FooterDashboard from "@/footer/dashboard";

export default {
  name: "layoutWise",

  components: {
    FooterDashboard,
    MenuDashboard,

  }
};
</script>
